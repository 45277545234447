import React from 'react';
import backgroundImage from '../../images/UI/sector-hero-bg.jpeg';
import mobileBackgroundImage from '../../images/UI/mobileBrandHeader.png';
import styled from 'styled-components';
import media from 'css-in-js-media';

const SectorHeroContainer = styled.div`
  height: 700px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%),
    url(${mobileBackgroundImage}) 50% / cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 25px;

  ${media('>tablet')} {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%),
      url(${backgroundImage}) 50% / cover no-repeat;
  }
`;

const SectorHeroTitleAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: -120px;
`;

const SectorHeroTitle = styled.h1`
  color: var(--colour-white);
  font-family: var(--light-body-font-family);
  font-style: normal;
  font-weight: 300;
  margin-bottom: 30px;
  max-width: 750px;
  text-align: center;

  font-size: 40px;
  line-height: 45px; /* 112.5% */

  ${media('>tablet')} {
    font-size: 60px;
    line-height: 65px; /* 106.25% */
    margin-bottom: 15px;
  }
`;

const SectorHeroSubtitle = styled.p`
  color: var(--colour-white);
  text-align: center;
  font-family: var(--light-body-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  max-width: 600px;
`;

const SectorHero = ({ title, subtitle }) => {
  return (
    <SectorHeroContainer>
      <SectorHeroTitleAndSubtitleContainer>
        <SectorHeroTitle>{title}</SectorHeroTitle>
        <SectorHeroSubtitle>{subtitle}</SectorHeroSubtitle>
      </SectorHeroTitleAndSubtitleContainer>
    </SectorHeroContainer>
  );
};

export default SectorHero;
