import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import media from 'css-in-js-media';
import '../fragments';
import LayoutContainer from '../components/LayoutContainer';
import PageSEO from '../components/PageSEO';
import ModulesContent, { getModuleBgColor } from '../components/ModulesContent';
import SectorHero from '../components/ui/SectorHero';

export const query = graphql`
  query GetSectorById($id: String!) {
    sanitySector(id: { eq: $id }) {
      _id
      title
      slug {
        current
      }
      headerBackground
      headerTitle
      headerSubtitle
      modules {
        ...Modules
      }
      seo {
        ...SEO
      }
    }
  }
`;

const Sector = ({ data }) => {
  const {
    sanitySector: sector,
  } = data;

  return (
    <LayoutContainer pageTitle={'Sectors'} headerBackground={'transparent'} showLetsTalkBanner>
      <PageSEO
        pageSEO={sector.seo}
        defaultTitle={sector.title}
        defaultDescription={sector.shortDescription}
      />
      {sector.headerBackground && (
        <SectorHero
          title={sector.headerTitle}
          subtitle={sector.headerSubtitle}
            image={sector.headerBackground}
        />
      )}      
      {sector.modules && <ModulesContent previousModuleBgColor={null} modules={sector.modules} />}
    </LayoutContainer>
  );
};

export default Sector;
